@import "core";
.layout-sidemenu {
	@include mediaMin {
		background-color: $color-bg;
	}
}
	
.section.sidemenu-template {
	display: flex;
	padding: 4.3rem 0 6rem;
	
	@include media {
		display: block;
		padding: 3rem 0 6rem;
	}

	.layout-sidemenu-aside {
		margin: 0 0 1.4rem;

		@include mediaMin {
			background-color: $color-white;
			padding: 2rem 2rem 1.2rem;
			border-radius: $radius-general;
			margin: 0 2rem 0 0;
			width: 28rem;
		}
	}

		.aside-title {
			@include font-bold;
			display: block;
			font-size: 1.6rem;

			@include media {
				font-size: 3.2rem;
			}
		}

		.aside-mobilenav {
			margin-top: 1.8rem;
		}

		.aside-menu {
			@include font-medium;
			margin-top: 1.8rem;
		}

			.menu-item {
				padding: 1.5rem 0;

				+ .menu-item {
					border-top: .1rem solid $color-border;
				}
			}

				.item-link {
					width: 100%;
					display: flex;
					justify-content: space-between;
					align-items: center;
					transition: $transition-hover color ease;
					line-height: 1.6rem;

					&.placeholder {
						height: 1.6rem;
						width: 100%;
					}

					&:hover, &.active {
						color: $color-primary;
					}

					&:not(.placeholder):not(.no-arrow) {
						&:after {
							@include icon('arrow-right');
							font-size: .9rem;
						}
					}
				}

				.item-contentwrap {
					display: flex;
					align-items: center;
					justify-content: flex-start;
				}

				.item-icon {
					margin-right: 1.5rem;
				}

	.layout-sidemenu-content {
		@include mediaMin {
			flex: 1 0 0;
			width: calc(100% - 30rem);
		}
	}

	.layout-sidemenu-section {
		+ .layout-sidemenu-section {
			margin-top: 4rem;
		}
		
		@include mediaMin {
			background-color: $color-white;
			padding: 2rem;
			border-radius: $radius-general;
			
			+ .layout-sidemenu-section {
				margin-top: 2rem;
			}
		}
	}

	.layout-sidebar-sectionheading {
		display: flex;
		align-items: center;
		margin-bottom: 2.6rem;
		padding-bottom: 1.2rem;
		
		&.lined {
			border-bottom: .1rem solid $color-border;
		}

		.sectionheading-content {
			flex: 1 0 0;
			display: flex;
			align-items: center;
			justify-content: flex-start;
		}

		.sectionheading-backbtn {
			margin-right: 1rem;
		}
	}
}